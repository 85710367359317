import { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import Swiper from 'swiper';
import { nanoid } from 'nanoid';
import { SwiperOptions } from 'swiper/types';
import { Pagination, Navigation } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import styles from './styles.module.css';
import { ISwiperSliderProps } from '../model/types';
import SwiperSliderButtons from './swiper-slider-buttons/swiper-slider-buttons';
import SliderCounter from './slider-counter/slider-counter';
import PhotoDescription from './photo-description/photo-description';

const SitesSwiperSlider = ({ photos = [], btnsPosition = 'center', showPhotoAlt }: ISwiperSliderProps) => {
  const id = nanoid();
  const swiperRef = useRef<HTMLDivElement>(null);
  const [swiperInstance, setSwiperInstance] = useState<Swiper | null>(null);

  const [currentSlide, setCurrentSlide] = useState(0);
  const totalSlides = photos?.length;

  const handleSlideChange = (swiper: Swiper) => {
    setCurrentSlide(swiper.realIndex);
  };

  const defaultOptions: SwiperOptions = {
    modules: [Navigation, Pagination],
    slidesPerView: 1,
    loop: true,
    watchOverflow: true,
    navigation: {
      nextEl: `div[data-id='${id}']`,
      prevEl: `div[data-id='${id}']`,
    },
    on: { slideChange: handleSlideChange },
  };

  useEffect(() => {
    if (swiperRef.current) {
      const swiper = new Swiper(swiperRef.current, defaultOptions);
      setSwiperInstance(swiper);

      return () => {
        swiper.destroy(true, true);
      };
    }
  }, [photos, btnsPosition]);

  useEffect(() => {
    // Заготовка для усложенния логики
    if (swiperInstance) {
      swiperInstance.update();
    }
  }, []);

  return (
    <div className={`swiper-container ${styles.container}`} data-id={id}>
      <div ref={swiperRef} className="swiper">
        <div className="swiper-wrapper">
          {photos?.map(({ src = '', alt = '' }, index) => (
            <div key={index} className={cn('swiper-slide')}>
              <img src={src} alt={alt} width={1440} height={720} draggable={false} className={styles.slide} />
            </div>
          ))}
        </div>
      </div>
      <SwiperSliderButtons id={id} btnsPosition={btnsPosition} />
      <SliderCounter totalSlides={totalSlides} currentSlide={currentSlide} />
      {Boolean(showPhotoAlt) && <PhotoDescription description={photos[currentSlide].alt} btnsPosition={btnsPosition} />}
    </div>
  );
};

export default SitesSwiperSlider;
