import styles from './swiper-slider-buttons.module.css';

import { ArrowRightSlider } from '@/shared/ui/icons/arrow-right-slider';
import { ArrowLeftSlider } from '@/shared/ui/icons/arrow-left-slider';

interface ISliderButtonsProps {
  id: string;
  btnsPosition?: 'bottom-left' | 'bottom-right' | 'center';
}

const SwiperSliderButtons = ({ id, btnsPosition = 'center' }: ISliderButtonsProps) => {
  return (
    <>
      <div data-id={id} className={styles.nextButton} data-position={btnsPosition}>
        <ArrowRightSlider />
      </div>
      <div data-id={id} className={styles.prevButton} data-position={btnsPosition}>
        <ArrowLeftSlider />
      </div>
    </>
  );
};

export default SwiperSliderButtons;
