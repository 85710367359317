import { Accordion } from '@/shared/ui/components/accordion';

import styles from './desktop-slider-dropdown.module.css';
import SitesSliderSwiper from '@/features/sites/slider-swiper';
import cs from 'classnames';
import { DesktopSliderDropdownProps } from '../../model/types';

export default function DesktopSliderDropdown({
  heading,
  options,
  moreInfoUrl,
  activeColor,
  inactiveColor,
  headingColor,
  moreInfoColor,
  reverse,
  activeIndex,
  setActiveIndex,
  headingPosition = 'center',
}: DesktopSliderDropdownProps) {
  const activeAccordionSlides = options[activeIndex].slides;

  return (
    <div className={cs(styles.sliderDropdownContainer, { [styles.reverse]: reverse })}>
      {heading && headingPosition === 'center' && (
        <h2 className={styles.heading} style={{ color: headingColor }}>
          {heading}
        </h2>
      )}

      <div className={cs(styles.content, { [styles.contentReverse]: reverse })}>
        <div className={styles.accordion}>
          {heading && headingPosition === 'above_list' && (
            <h2 className={cs(styles.heading, styles.headingAboveList)} style={{ color: headingColor }}>
              {heading}
            </h2>
          )}
          <div>
            {options.map(({ title, description }, index) => (
              <Accordion
                key={index}
                color={activeIndex === index ? activeColor : inactiveColor}
                title={title}
                content={description}
                onClick={() => setActiveIndex(index)}
                isOpen={activeIndex === index}
              />
            ))}
          </div>
          {moreInfoUrl && (
            <a
              className={styles.moreInfoBtn}
              href={moreInfoUrl}
              style={{ color: moreInfoColor, borderColor: moreInfoColor }}
            >
              Подробнее
            </a>
          )}
        </div>
        {activeAccordionSlides && (
          <SitesSliderSwiper photos={activeAccordionSlides} btnsPosition="bottom-left" showPhotoAlt />
        )}
      </div>
    </div>
  );
}
