import { SitesSliderDropdown, SitesSliderDropdownMockData } from '@/widgets/sites/slider-dropdown';
import styles from './test-page.module.css';
import { useState } from 'react';

const TestPage = () => {
  const [isMoreInfo, setIsMoreInfo] = useState(false);
  const [colors, setColors] = useState({
    activeColor: 'black',
    inactiveColor: 'black',
    headingColor: '',
    moreInfoColor: '',
  });
  const [heading, setHeading] = useState(false);
  const [reverse, setReverse] = useState(false);
  const [headingPosition, setHeadingPosition] = useState<'center' | 'above_list'>('center');
  return (
    <div className={styles.container}>
      Test Page
      <div style={{ fontSize: '20px' }}>
        <p>
          Показывать Заголовок{' '}
          <input type="checkbox" checked={heading} onChange={(e) => setHeading(e.target.checked)} />
        </p>
        <p>
          Позиция заголовка :{' '}
          <select
            value={headingPosition}
            onChange={(e) => setHeadingPosition(e.target.value as 'center' | 'above_list')}
          >
            <option value="center">Центр</option>
            <option value="above_list">Над списком</option>
          </select>
        </p>
        <p>
          Отзеркалить <input type="checkbox" checked={reverse} onChange={(e) => setReverse(e.target.checked)} />
        </p>
        <p>
          Показывать кнопку подробнее{' '}
          <input type="checkbox" checked={isMoreInfo} onChange={(e) => setIsMoreInfo(e.target.checked)} />
        </p>
        <p>
          Цвет заголовка :{' '}
          <input
            type="color"
            onChange={(e) => setColors({ ...colors, headingColor: e.target.value })}
            value={colors.headingColor}
          />
        </p>
        <p>
          Цвет активного аккордиона :{' '}
          <input
            type="color"
            value={colors.activeColor}
            onChange={(e) => setColors({ ...colors, activeColor: e.target.value })}
          />
        </p>
        <p>
          Цвет неактивного аккордиона :{' '}
          <input
            type="color"
            value={colors.inactiveColor}
            onChange={(e) => setColors({ ...colors, inactiveColor: e.target.value })}
          />
        </p>
        <p>
          Цвет кнопки подробнее :{' '}
          <input
            type="color"
            value={colors.moreInfoColor}
            onChange={(e) => setColors({ ...colors, moreInfoColor: e.target.value })}
          />
        </p>
      </div>
      <SitesSliderDropdown
        {...colors}
        heading={heading ? 'Тестовый заголовок' : ''}
        options={SitesSliderDropdownMockData}
        moreInfoUrl={isMoreInfo ? '/#' : ''}
        reverse={reverse}
        headingPosition={headingPosition}
      />
    </div>
  );
};

export default TestPage;
