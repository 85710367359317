import styles from './slider-counter.module.css';

interface ISliderCounterProps {
  currentSlide?: number;
  totalSlides?: number;
}

const SliderCounter = ({ totalSlides = 0, currentSlide = 0 }: ISliderCounterProps) => {
  if (!totalSlides || totalSlides === 1) return null;
  return (
    <div className={styles.counter}>
      {currentSlide + 1} / {totalSlides}
    </div>
  );
};

export default SliderCounter;
