import { useCallback, useEffect, useRef, useState } from 'react';
import styles from './mobile-accordion.module.css';
import cs from 'classnames';
import { MobileAccordionProps } from '../../model/types';
import SitesSliderSwiper from '@/features/sites/slider-swiper';
export default function MobileAccordion({
  title,
  isDisabled,
  isOpen,
  content,
  onClick,
  color,
  className,
  slides,
}: MobileAccordionProps) {
  const [localOpenState, setLocalOpenState] = useState(false);
  const contentRef = useRef<HTMLDivElement | null>(null);

  const onClickHandler = useCallback(() => {
    if (onClick) {
      onClick();
    } else {
      setLocalOpenState((prev) => !prev);
    }
  }, [onClick]);

  const isContentOpen = isOpen || localOpenState;

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.style.maxHeight = isContentOpen ? `${contentRef.current.scrollHeight}px` : '0px';
    }
  }, [isOpen]);

  return (
    <>
      <div className={styles.divider} style={{ backgroundColor: color }} />
      <div
        className={cs(styles.dropdownContainer, className, { [styles.disabled]: isDisabled })}
        style={{ color: color, borderColor: color }}
        onClick={() => !isDisabled && onClickHandler()}
      >
        <div className={styles.dropdownTitle} style={{ color: color }}>
          <div className={cs(styles.title)}>{title}</div>
          <div className={cs(styles.arrow, { [styles.arrowActive]: isContentOpen })}>
            <svg className={styles.arrowIcon} viewBox="0 0 20 11" fill="none">
              <path stroke={color ?? '#000'} d="m19 1-9 9-9-9" />
            </svg>
          </div>
        </div>
        <div ref={contentRef} className={cs(styles.dropdownContent, { [styles.dropdownContentActive]: isContentOpen })}>
          <div className={styles.content}>{content}</div>
          <div className={styles.slider}>
            <SitesSliderSwiper photos={slides} showPhotoAlt />
          </div>
        </div>
      </div>
      <div className={styles.dropdownTitle} style={{ backgroundColor: color }} />
    </>
  );
}
